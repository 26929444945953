export interface IStatisticsCounts {
  activeCustomers: number
  processedOrders: number
  paidOrders: number
  pendingExchangeOrders: number
}

export interface IMostActiveCustomer {
  id: string
  fullName: string
  email: string
  ordersCount: number
}

export interface IBestSellingProduct {
  id: number
  name: string
  salesCount: number
}

export enum TimelineChartType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface ITimelineChartPoint {
  value: number
  date: Date
}

export interface IOrdersStatusesOverview {
  pendingOrders: number
  partiallyPaidOrders: number
  paymentInProgressOrders: number
  paidOrders: number
  processedOrders: number
  refundedOrders: number
}

export interface IExchangeOrdersStatusesOverview {
  pendingOrders: number
  acceptedOrders: number
  rejectedOrders: number
  paidOrders: number
}
