import { Card, Spin } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { IOrdersStatusesOverview } from '../../../../models/home'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

const OrdersStatusesOverview: React.FC = () => {
  const { t } = useTranslation()

  const [data, setData] = useState<any>()

  const query = useQuery({
    queryKey: ['orders-statuses-overview'],
    queryFn: async () => {
      return axios.get<IOrdersStatusesOverview>(
        '/statistics/orders-statuses-overview'
      )
    },
    onSuccess({ data }) {
      const chartData = {
        labels: [
          t('order.status_pending'),
          t('order.status_payment_in_progress'),
          t('order.status_partially_paid'),
          t('order.status_paid'),
          t('order.status_processed'),
          t('order.status_refunded'),
        ],

        datasets: [
          {
            data: [
              data.pendingOrders,
              data.paymentInProgressOrders,
              data.partiallyPaidOrders,
              data.paidOrders,
              data.processedOrders,
              data.refundedOrders,
            ],
            backgroundColor: [
              '#facf1f',
              '#8b5cf6',
              '#4a4cf0',
              '#f47a13',
              '#12ccaa',
              '#24174b',
            ],
          },
        ],
      }
      setData(chartData)
    },
  })

  return (
    <Spin spinning={query.isLoading}>
      <Card
        title={t('home.orders_statuses')}
        headStyle={{
          textAlign: 'center',
        }}
        bodyStyle={{
          height: '20rem',
        }}
      >
        {data && (
          <Pie
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        )}
      </Card>
    </Spin>
  )
}

export default OrdersStatusesOverview
