import { Divider, Input, Modal } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IOrderDetails, IProcessOrder } from '../../../../models/order'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

interface IProps {
  orderId: string
  order?: IOrderDetails
  visible: boolean
  setVisible: (visible: boolean) => void
}

interface IProductData {
  product: string
  quantity: number
  codes: string[]
  notes: string
}

const ProcessOrderModal: React.FC<IProps> = ({
  orderId,
  order,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [data, setData] = React.useState<IProductData[]>([])

  React.useEffect(() => {
    if (!order) return

    const data = order?.lines.map((l) => {
      return {
        product: l.product.name,
        quantity: l.quantity,
        notes: '',
        codes: Array.from({ length: l.quantity }, () => ''),
      }
    })
    setData(data)
  }, [order])

  const mutation = useMutation({
    mutationFn: (data: IProcessOrder) => {
      return axios.post(`/order/${orderId}/process`, data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['orders'] })
      queryClient.invalidateQueries({ queryKey: ['order', orderId] })
      setVisible(false)
    },
  })

  return (
    <Modal
      title={t('order.process_order')}
      open={visible}
      onOk={() => {
        let value = ''

        data.forEach((d, index) => {
          value += `${d.product} (${d.quantity}):\n`
          d.codes.forEach((c) => {
            value += `• ${c}\n`
          })
          if (d.notes) {
            value += 'Important Notes:\n'
            value += `${d.notes}\n`
          }
          if (index < data.length - 1) {
            value += '-------------------------\n'
          }
        })

        mutation.mutate({
          data: value,
        })
      }}
      okButtonProps={{
        disabled: data.some((d) => d.codes.some((c) => c === '')),
      }}
      onCancel={() => setVisible(false)}
      confirmLoading={mutation.isLoading}
    >
      {data.map((d, index) => (
        <div>
          <h4 style={{ marginBottom: 12 }}>
            {d.product} ({d.quantity})
          </h4>
          {d.codes.map((c, i) => (
            <Input
              required
              value={c}
              onChange={(e) => {
                var updatedData = [...data]
                updatedData[index].codes[i] = e.currentTarget.value
                setData(updatedData)
              }}
              placeholder={`Code #${i + 1}`}
              style={{ marginBottom: 12 }}
            />
          ))}
          <div>
            Notes
            <Input.TextArea
              value={d.notes}
              onChange={(e) => {
                var updatedData = [...data]
                updatedData[index].notes = e.currentTarget.value
                setData(updatedData)
              }}
            />
          </div>
          {index < data.length - 1 && (
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
          )}
        </div>
      ))}
    </Modal>
  )
}

export default ProcessOrderModal
