import { Modal } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import axios from 'axios'

interface IProps {
  orderId: string
  visible: boolean
  setVisible: (visible: boolean) => void
}

const ResendEmailOrderModal: React.FC<IProps> = ({
  orderId,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation()

  const mutation = useMutation({
    mutationFn: () => {
      return axios.post(`/order/${orderId}/resend-email`)
    },
    onSuccess() {
      setVisible(false)
    },
  })

  return (
    <Modal
      title={t('order.resend_email')}
      open={visible}
      onOk={() => {
        mutation.mutate()
      }}
      onCancel={() => setVisible(false)}
      confirmLoading={mutation.isLoading}
    >
      Are you sure you want to re-send the proccessing email again?
    </Modal>
  )
}

export default ResendEmailOrderModal
