import { Button, Card, Table } from 'antd'
import { SorterResult } from 'antd/es/table/interface'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import fileDownload from 'js-file-download'

import { FileExcelOutlined } from '@ant-design/icons'

import HeaderCard from '../../components/common/header-card'
import { IPaginatedList } from '../../models/paginated-list'
import {
  DATE_TIME_FORMAT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_FUNCTION,
  INITIAL_PAGE,
} from '../../utils/constants'

import type { ColumnsType } from 'antd/es/table'
import { IOrder } from '../../models/order'
import { ICustomer } from '../../models/customer'
import { ISort } from '../../models/shared'
import { useKeycloak } from '@react-keycloak/web'
import { P_SHOW_CUSTOMERS } from '../../utils/rbac/permissions'
import { useNavigate } from 'react-router-dom'

const CustomersPage: React.FC = () => {
  const {
    keycloak: { hasRealmRole },
  } = useKeycloak()

  const navigate = useNavigate()

  if (!hasRealmRole(P_SHOW_CUSTOMERS)) {
    navigate('/403')
  }

  const { t } = useTranslation()

  const [page, setPage] = useState(INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [search, setSearch] = useState<string | undefined>()
  const [sort, setSort] = useState<ISort | undefined>()

  const query = useQuery({
    queryKey: ['customer', page, pageSize, search, sort],
    queryFn: async () => {
      return axios.get<IPaginatedList<ICustomer>>('/customer', {
        params: {
          page,
          pageSize,
          search,
          sortKey: sort?.name,
          sortDirection: sort?.direction,
        },
      })
    },
  })

  const columns: ColumnsType<ICustomer> = [
    {
      title: t('customer.full_name'),
      dataIndex: 'fullName',
    },
    {
      title: t('customer.email'),
      dataIndex: 'email',
    },
    {
      title: t('customer.orders_count'),
      dataIndex: 'ordersCount',
      key: 'ordersCount',
      sorter: DEFAULT_SORT_FUNCTION,
    },
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (dateTime) => (
        <div style={{ width: 150 }}>
          {dayjs(dateTime).format(DATE_TIME_FORMAT)}
        </div>
      ),
      sorter: DEFAULT_SORT_FUNCTION,
    },
    {
      title: t('general.updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (dateTime) => (
        <div style={{ width: 150 }}>
          {dayjs(dateTime).format(DATE_TIME_FORMAT)}
        </div>
      ),
      sorter: DEFAULT_SORT_FUNCTION,
    },
  ]

  return (
    <>
      <HeaderCard
        title={t('customer.label')}
        onSearch={(value) => {
          setSearch(value.length === 0 ? undefined : value)
        }}
        trailing={[
          <Button
            icon={<FileExcelOutlined />}
            onClick={async () => {
              const response = await axios.get(
                '/customer/generate-excel-file',
                {
                  params: {
                    search,
                    sortKey: sort?.name,
                    sortDirection: sort?.direction,
                  },
                  responseType: 'blob',
                }
              )
              fileDownload(response.data, 'customers.xlsx')
            }}
          >
            {t('general.export_to_excel')}
          </Button>,
        ]}
      />
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Table
          loading={query.isFetching}
          columns={columns}
          dataSource={query.data?.data.data}
          rowKey={(record) => record.id}
          style={{ overflowX: 'scroll' }}
          onChange={(_, __, sorter, ___) => {
            const { columnKey, order } = sorter as SorterResult<IOrder>

            if (!order) {
              setSort(undefined)
            } else {
              setSort({
                name: columnKey as string,
                direction: order === 'ascend' ? 'ASC' : 'DESC',
              })
            }
          }}
          pagination={{
            current: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            showSizeChanger: true,
            total: query.data?.data.pagination.count,
            position: ['bottomCenter'],
            onChange(p, ps) {
              if (pageSize !== ps) setPage(INITIAL_PAGE)
              else setPage(p)
              setPageSize(ps)
            },
            showTotal: (total, range) => {
              return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
                'general.from'
              )} ${total} ${t('general.items')}`
            },
          }}
        />
      </Card>
    </>
  )
}

export default CustomersPage
