import React, { useState } from 'react'
import { Card, Button, Descriptions, Spin, Space, Table } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  CheckOutlined,
  RollbackOutlined,
  SendOutlined,
} from '@ant-design/icons'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import {
  IOrderDetails,
  IOrderLineProduct,
  OrderStatus,
} from '../../../models/order'
import OrderStatusTag from '../../../components/enums/order-status-tag'
import OrderPaymentMethodTag from '../../../components/enums/order-payment-method-tag'
import ProcessOrderModal from '../../../components/modals/order/process-order'
import RefundOrderModal from '../../../components/modals/order/refund-order'
import EditOrderModal from '../../../components/modals/order/edit-order'
import DeleteOrderModal from '../../../components/modals/order/delete-order'
import PayOrderModal from '../../../components/modals/order/pay-order'
import { useKeycloak } from '@react-keycloak/web'
import {
  P_DELETE_ORDER,
  P_PAY_ORDER,
  P_PROCESS_ORDER,
  P_REFUND_ORDER,
  P_RESEND_EMAIL_ORDER,
  P_SHOW_ORDERS,
  P_UPDATE_ORDER,
} from '../../../utils/rbac/permissions'
import ResendEmailOrderModal from '../../../components/modals/order/resend-email-order'

const OrderDetailsPage: React.FC = () => {
  const {
    keycloak: { hasRealmRole },
  } = useKeycloak()

  const navigate = useNavigate()

  if (!hasRealmRole(P_SHOW_ORDERS)) {
    navigate('/403')
  }

  const { t } = useTranslation()

  const { id } = useParams()

  const [payModalVisible, setPayModalVisible] = useState(false)
  const [processModalVisible, setProcessModalVisible] = useState(false)
  const [refundModalVisible, setRefundModalVisible] = useState(false)
  const [resendEmailModalVisible, setResendEmailModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const query = useQuery({
    queryKey: ['order', id],
    queryFn: async () => {
      return axios.get<IOrderDetails>(`/order/${id}`)
    },
  })

  var data = query.data?.data

  return (
    <div>
      <PayOrderModal
        orderId={`${id}`}
        visible={payModalVisible}
        setVisible={setPayModalVisible}
      />
      <ProcessOrderModal
        orderId={`${id}`}
        order={data}
        visible={processModalVisible}
        setVisible={setProcessModalVisible}
      />
      <RefundOrderModal
        orderId={`${id}`}
        visible={refundModalVisible}
        setVisible={setRefundModalVisible}
      />
      <ResendEmailOrderModal
        orderId={`${id}`}
        visible={resendEmailModalVisible}
        setVisible={setResendEmailModalVisible}
      />
      <EditOrderModal
        orderId={`${id}`}
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        {...data}
      />
      <DeleteOrderModal
        orderId={`${id}`}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
      />
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('order.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Space>
              <Button
                type="default"
                icon={<CloseOutlined />}
                onClick={() => navigate(-1)}
              >
                {t('general.cancel')}
              </Button>
              {hasRealmRole(P_UPDATE_ORDER) && (
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => setEditModalVisible(true)}
                >
                  {t('general.edit')}
                </Button>
              )}
              {data?.status === OrderStatus.PENDING &&
                hasRealmRole(P_PAY_ORDER) && (
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={() => setPayModalVisible(true)}
                  >
                    {t('order.pay')}
                  </Button>
                )}
              {data?.status === OrderStatus.PAID &&
                hasRealmRole(P_PROCESS_ORDER) && (
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={() => setProcessModalVisible(true)}
                  >
                    {t('order.process')}
                  </Button>
                )}
              {(data?.status === OrderStatus.PAID ||
                data?.status === OrderStatus.PARTIALLY_PAID ||
                data?.status === OrderStatus.PROCESSED) &&
                hasRealmRole(P_REFUND_ORDER) && (
                  <Button
                    type="primary"
                    icon={<RollbackOutlined />}
                    onClick={() => setRefundModalVisible(true)}
                  >
                    {t('order.refund')}
                  </Button>
                )}
              {data?.status === OrderStatus.PROCESSED &&
                hasRealmRole(P_RESEND_EMAIL_ORDER) && (
                  <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={() => setResendEmailModalVisible(true)}
                  >
                    {t('order.resend_email')}
                  </Button>
                )}
              {hasRealmRole(P_DELETE_ORDER) && (
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => setDeleteModalVisible(true)}
                >
                  {t('general.delete')}
                </Button>
              )}
            </Space>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.sequence')}>
              {data?.sequence}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.full_name')}>
              {data?.fullName}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.email')}>
              <a href={`mailto:${data?.email}`}>{data?.email}</a>
            </Descriptions.Item>
            <Descriptions.Item label={t('order.status')}>
              {data?.status && <OrderStatusTag status={data?.status} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.note')}>
              {data?.note}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.data')}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.data?.replaceAll('\n', '<br/>') ?? '',
                }}
              ></div>
            </Descriptions.Item>
            <Descriptions.Item label={t('order.refund_reason')}>
              {data?.refundReason}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.total_price')}>
              {data?.totalPrice} {data?.currency}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.paid_amount')}>
              {data?.paidAmount && data?.paidAmount}{' '}
              {data?.paidAmount && data?.currency}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.crypto_paid_amount')}>
              {data?.cryptoPaidAmount && data?.cryptoPaidAmount}{' '}
              {data?.cryptoCurrency && data?.cryptoCurrency}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.payment_method')}>
              {data?.paymentMethod && (
                <OrderPaymentMethodTag method={data?.paymentMethod} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.provider_trx_number')}>
              {data?.providerTrxNumber && data?.providerTrxNumber}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.products')}>
              <Table
                bordered
                columns={[
                  {
                    title: t('order.product_name'),
                    dataIndex: 'product',
                    render: (product: IOrderLineProduct) => product?.name,
                  },
                  {
                    title: t('order.quantity'),
                    dataIndex: 'quantity',
                  },
                  {
                    title: t('order.unit_price'),
                    dataIndex: 'unitPrice',
                  },
                  {
                    title: t('order.total_price'),
                    dataIndex: 'totalPrice',
                  },
                ]}
                dataSource={[
                  ...(data?.lines ?? []),
                  { totalPrice: `${data?.totalPrice} ${data?.currency}` },
                ]}
                pagination={false}
              />
            </Descriptions.Item>
            <Descriptions.Item label={t('order.processed_by')}>
              {data?.processedBy && data?.processedBy}
            </Descriptions.Item>
            <Descriptions.Item label={t('order.refunded_by')}>
              {data?.refundedBy && data?.refundedBy}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_at')}>
              {data?.createdAt &&
                dayjs(data.createdAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_at')}>
              {data?.updatedAt &&
                dayjs(data.updatedAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>
    </div>
  )
}

export default OrderDetailsPage
