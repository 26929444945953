import React from 'react'
import {
  LogoutOutlined,
  UserOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Avatar, Dropdown, MenuProps } from 'antd'
import cn from 'classnames'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useKeycloak } from '@react-keycloak/web'

const UserSettings: React.FC = () => {
  const { t } = useTranslation()
  const { keycloak } = useKeycloak()

  const onLogout = async () => {
    keycloak.logout()
  }

  const onAccountSettings = async () => {
    // window.open('https://auth.mygiftcardy.com/realms/gift-cardy/account')
    keycloak.accountManagement()
  }

  const menuItems: MenuProps['items'] = [
    {
      label: t('auth.account_settings'),
      key: '1',
      icon: <SettingOutlined />,
      onClick: onAccountSettings,
    },
    {
      type: 'divider',
    },
    {
      label: t('auth.logout'),
      key: '2',
      icon: <LogoutOutlined />,
      danger: true,
      onClick: onLogout,
    },
  ]

  return (
    <>
      <Dropdown
        menu={{ items: menuItems }}
        trigger={['click']}
        className={cn(styles.userSettings)}
      >
        <div>
          <span style={{ marginInlineEnd: '1rem' }}>
            Hi, {keycloak.tokenParsed?.preferred_username}
          </span>
          <Avatar shape="square" size="large" icon={<UserOutlined />} />
        </div>
      </Dropdown>
    </>
  )
}

export default UserSettings
