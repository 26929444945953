import { Line } from 'react-chartjs-2'
import { Card, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITimelineChartPoint, TimelineChartType } from '../../../../models/home'
import { useQuery } from 'react-query'
import axios from 'axios'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { OrderStatus } from '../../../../models/order'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../../../utils/constants'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const OrdersCountTimelineDaily: React.FC = () => {
  const { t } = useTranslation()

  const [fromDate, setFromDate] = useState<string>(
    dayjs(new Date()).format(DATE_FORMAT)
  )
  const [toDate, setToDate] = useState<string>(
    dayjs(new Date()).format(DATE_FORMAT)
  )

  const [data, setData] = useState<any>()

  useEffect(() => {
    const now = dayjs(new Date())
    setFromDate(now.subtract(30, 'day').format(DATE_FORMAT))
    setToDate(now.format(DATE_FORMAT))
  }, [])

  const query = useQuery({
    queryKey: ['statistics-orders-count-timeline-daily', fromDate, toDate],
    queryFn: async () => {
      const allOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/orders-count-timeline',
        {
          params: {
            type: TimelineChartType.DAILY,
            fromDate,
            toDate,
          },
        }
      )

      const pendingOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/orders-count-timeline',
        {
          params: {
            type: TimelineChartType.DAILY,
            fromDate,
            toDate,
            status: OrderStatus.PENDING,
          },
        }
      )

      const paymentInProgressOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/orders-count-timeline',
        {
          params: {
            type: TimelineChartType.DAILY,
            fromDate,
            toDate,
            status: OrderStatus.PAYMENT_IN_PROGRESS,
          },
        }
      )

      const partiallyPaidOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/orders-count-timeline',
        {
          params: {
            type: TimelineChartType.DAILY,
            fromDate,
            toDate,
            status: OrderStatus.PARTIALLY_PAID,
          },
        }
      )

      const paidOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/orders-count-timeline',
        {
          params: {
            type: TimelineChartType.DAILY,
            fromDate,
            toDate,
            status: OrderStatus.PAID,
          },
        }
      )

      const processedOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/orders-count-timeline',
        {
          params: {
            type: TimelineChartType.DAILY,
            fromDate,
            toDate,
            status: OrderStatus.PROCESSED,
          },
        }
      )

      const refundedOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/orders-count-timeline',
        {
          params: {
            type: TimelineChartType.DAILY,
            fromDate,
            toDate,
            status: OrderStatus.REFUNDED,
          },
        }
      )

      return {
        allOrders: allOrders.data,
        pendingOrders: pendingOrders.data,
        paymentInProgressOrders: paymentInProgressOrders.data,
        partiallyPaidOrders: partiallyPaidOrders.data,
        paidOrders: paidOrders.data,
        processedOrders: processedOrders.data,
        refundedOrders: refundedOrders.data,
      }
    },
    onSuccess(data) {
      const labels = data.allOrders.map((p) => p.date)
      const allOrders = data.allOrders.map((p) => p.value)
      const pendingOrders = data.pendingOrders.map((p) => p.value)
      const paymentInProgressOrders = data.paymentInProgressOrders.map(
        (p) => p.value
      )
      const partiallyPaidOrders = data.partiallyPaidOrders.map((p) => p.value)
      const paidOrders = data.paidOrders.map((p) => p.value)
      const processedOrders = data.processedOrders.map((p) => p.value)
      const refundedOrders = data.refundedOrders.map((p) => p.value)

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: t('home.all'),
            data: allOrders,
            backgroundColor: '#a5a5a5',
            borderColor: '#a5a5a5',
          },
          {
            label: t('order.status_pending'),
            data: pendingOrders,
            backgroundColor: '#facf1f',
            borderColor: '#facf1f',
          },
          {
            label: t('order.status_payment_in_progress'),
            data: paymentInProgressOrders,
            backgroundColor: '#8b5cf6',
            borderColor: '#8b5cf6',
          },
          {
            label: t('order.status_partially_paid'),
            data: partiallyPaidOrders,
            backgroundColor: '#4a4cf0',
            borderColor: '#4a4cf0',
          },
          {
            label: t('order.status_paid'),
            data: paidOrders,
            backgroundColor: '#f47a13',
            borderColor: '#f47a13',
          },
          {
            label: t('order.status_processed'),
            data: processedOrders,
            backgroundColor: '#12ccaa',
            borderColor: '#12ccaa',
          },
          {
            label: t('order.status_refunded'),
            data: refundedOrders,
            backgroundColor: '#24174b',
            borderColor: '#24174b',
          },
        ],
      }
      setData(chartData)
    },
  })

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  }

  return (
    <Spin spinning={query.isLoading}>
      <Card
        title={`${t('home.orders_count_timeline')} (${t('home.daily')})`}
        headStyle={{
          textAlign: 'center',
        }}
        bodyStyle={{
          height: '28rem',
          width: '100%',
        }}
      >
        {data && (
          <Line
            data={data}
            options={options}
            style={{
              width: '100%',
            }}
          />
        )}
      </Card>
    </Spin>
  )
}

export default OrdersCountTimelineDaily
