export enum OrderStatus {
  PENDING = 'PENDING',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
  PAID = 'PAID',
  PROCESSED = 'PROCESSED',
  REFUNDED = 'REFUNDED',
}

export enum OrderPaymentMethod {
  MANUAL = 'MANUAL',
  COIN_PAYMENTS = 'COIN_PAYMENTS',
  PERFECT_MONEY = 'PERFECT_MONEY',
  LUNU = 'LUNU',
}

export interface IOrder {
  id: string
  sequence: string
  fullName: string
  email: string
  status: OrderStatus
  totalPrice: number
  currency: string
  paymentMethod: OrderPaymentMethod
  createdAt: Date
  updatedAt: Date
  processedBy?: string
  refundedBy?: string
}

export interface IOrderLineProduct {
  id: string
  name: string
  slug: string
}

export interface IOrderLine {
  product: IOrderLineProduct
  quantity: number
  unitPrice: number
  totalPrice: number
}

export interface IOrderDetails extends IOrder {
  note?: string
  data?: string
  refundReason?: string
  paidAmount?: number
  cryptoPaidAmount?: number
  cryptoCurrency?: string
  providerTrxNumber?: string
  lines: IOrderLine[]
}

export interface IProcessOrder {
  data?: string
}

export interface IRefundOrder {
  refundReason?: string
}

export interface IEditOrder {
  note?: string
  data?: string
  refundReason?: string
}
