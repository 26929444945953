export const P_SHOW_ORDERS = 'show-orders'
export const P_UPDATE_ORDER = 'update-order'
export const P_DELETE_ORDER = 'delete-order'
export const P_PAY_ORDER = 'pay-order'
export const P_PROCESS_ORDER = 'process-order'
export const P_REFUND_ORDER = 'refund-order'
export const P_RESEND_EMAIL_ORDER = 'resend-email-order'

export const P_SHOW_EXCHANGE_ORDERS = 'show-exchange-orders'
export const P_UPDATE_EXCHANGE_ORDER = 'update-exchange-order'
export const P_DELETE_EXCHANGE_ORDER = 'delete-exchange-order'
export const P_ACCEPT_EXCHANGE_ORDER = 'accept-exchange-order'
export const P_REJECT_EXCHANGE_ORDER = 'reject-exchange-order'
export const P_PAY_EXCHANGE_ORDER = 'pay-exchange-order'

export const P_SHOW_CUSTOMERS = 'show-customers'

export const P_SHOW_STATISTICS = 'show-statistics'
